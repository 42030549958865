import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../layouts/default"

import Breadcrumb from "../components/breadcrumb"
import Paragraph from "../components/paragraph"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import Grid from "../components/grid"
import HyperCardStacked from "../components/hyper-card-stacked"

const TurmTv = () => {
  const data = useStaticQuery(graphql`
    query {
      ogImage: file(relativePath: { eq: "og_images/videos.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
      videos: file(relativePath: { eq: "turm-tv/expertinnen.jpg" }) {
        ...mediumImage
      }
      turm: file(relativePath: { eq: "turm-tv/live.jpg" }) {
        ...mediumImage
      }
    }
  `)
  return (
    <Layout backdrop="videos">
      <Seo
        title="Turm-TV"
        description="In Expert*innen-Videos, Interviews und Live-Rundgängen greifen die Beiträge aus dem Turm-TV unterschiedlichste Themen aus Friedrich Hölderlins Biografie, Literatur und Wirkungsgeschichte auf und vermitteln sie."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Turm-TV",
                link: "/turm-tv",
              },
            ]}
          />
          <PageTitle>Turm-TV</PageTitle>
          <Paragraph dropcap={true}>
            Kurz und knackig, lebendig und verständlich: In Expert*innen-Videos,
            Interviews und Live-Rundgängen greifen die Beiträge aus dem Turm-TV
            unterschiedlichste Themen aus Friedrich Hölderlins Biografie,
            Literatur und Wirkungsgeschichte auf und vermitteln sie.
          </Paragraph>
          <Grid columns={[null, null, 2]} space={6}>
            <HyperCardStacked
              title="Expert*innen-Videos"
              headingElement="h2"
              description={`Expert*innen aus der Kunst und Kultur veranschaulichen die Machart und Wirkung von Hölderlins Werken sowie die zeitgeschichtlichen Ereignisse, vor denen sie entstanden sind.`}
              to="/videos"
              cta="Jetzt ansehen"
              image={data.videos}
            />
            <HyperCardStacked
              title="Hölderlinturm Live"
              headingElement="h2"
              description="In unseren Live-Videos werden aktuelle Ereignisse aus unserem Museumsalltag zum Ausgangspunkt kurzer Rückblicke, Ausstellungsrundgänge oder Interviews mit Kooperationspartner*innen."
              to="/live"
              cta="Jetzt ansehen"
              image={data.turm}
            />
          </Grid>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default TurmTv
